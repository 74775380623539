/*********************************************\
		Desktop First Method
\*********************************************/
/* Medium Devices, Notebooks */
@media only screen and (max-width : 1130px) {
	.container {width: 960px;}
}


/* Small Devices, Tablets */
@media only screen and (max-width : 960px) {
 .container {width: 720px;}
 br.sm {display: block;}
 br.xs {display: none;}
	.main-header {
		padding-top: 20px;
		.date {
			// float: right;
			text-align: right;
			background: none;
			font-weight: 600;
			position: absolute;
			right: 0;
			top: -70px;
			span {
				background-color: #3e5ffa;
				display: block;
				padding-left: 5px;
				padding-right: 5px;
				font-weight: 400;
				line-height: 22px;
			}
		}
		.content-block {
			padding-top: 35px;
		}
		.title {
			font-size: 36px;
			line-height: 49px;
			margin-bottom: 30px;
			span {
				font-size: 20px;
				line-height: 30px;
			}
		}
		.desc {
			max-width: 100%;
			margin-bottom: 50px;
		}
		.bottom-text {
			max-width: 100%;
			text-align: center;
			padding-left: 160px;
			padding-right: 160px;
			background: url(../img/sm/bottom-text-bg-sm.png) 50% 0 no-repeat;
			-webkit-background-size: 100% 110px;
			     -o-background-size: 100% 110px;
			        background-size: 100% 110px;
			margin-bottom: 50px;
			height: 110px;
		}
		.form-block {
			.form-title {
				br {display: none;}
				span {margin-top: 10px;}
			}
			.form-bottom-text {
				br {display: none;}
			}
		}
		form.standart-form {
			max-width: 100%;
			.subm {
				display: inline-block;
				vertical-align: top;
			}
			.part4 {display: none;}
		}
	}
	.sec1 {
		background-color: #f3f3f3;
		padding-bottom: 60px;
		.logos {
			-webkit-background-size: 100%;
			     -o-background-size: 100%;
			        background-size: 100%;
		}
		
	}
	.sec2 {
		.sec-title {
			z-index: 2;
			margin-left: 80px;
			&:before {
				z-index: -1;
			}
		}
		.item {
			margin: 0 auto;
			padding-top: 10px;
			max-width: 570px;
			margin-bottom: 40px;
		}
	}
	.sec3 {
		.sec-title {
			z-index: 2;
			margin-left: 80px;
			&:before {
				z-index: -1;
			}
		}
		.item {
			margin: 0 auto;
			padding-left: 160px;
			padding-top: 10px;
			max-width: 600px;
			margin-bottom: 55px;
			min-height: 144px;
		}
		.btn {
			margin-top: 0;
		}
	}
	.sec4 {
		padding-top: 60px;
		.sec-title {
			font-size: 26px;
			line-height: 40px;
			margin-bottom: 50px;
			br {display: none;}
		}
		.day {
			.date {
				font-size: 13px;
			}
			.session-number {
				font-size: 15px;
				margin-right: 33px;
				
				span {
					font-size: 45px;
					line-height: 30px;
				}
			}
			.session-title {
				font-size: 20px;
				line-height: 28px;
				margin-bottom: 30px;
			}
		}
	}
	.sec5 {
		padding-bottom: 70px;
		padding-top: 90px;
		.sec-title {
			font-size: 26px;
			line-height: 40px;
			margin-bottom: 55px;
		}
		.block {
			margin-bottom: 70px;
			.block-number {
				display: inline-block;
				vertical-align: top;
				margin-right: 36px;
				margin-left: 75px;
			}
			.block-title {
				display: inline-block;
				vertical-align: top;
			}
			.block-text {
				padding-left: 150px;
				padding-right: 150px;
				margin-bottom: 45px;
			}
		}
		.block2 {
			.video {
				margin: 0 auto;
			}
		}
		.block3 {
			.bi3 {
				background-position: 50%;
			}
		}
	}
	.sec6 {
		padding-top: 213px;
		position: relative;
		.infoblock {
			margin: 0 auto;
			transform: none;
			display: block;
		}
		.videobtn {
			margin: 0;
			position: absolute;
			top: 70px;
			left: 50%;
			transform: translateX(-50%);
		}
	}
	.modal-overlay {
		.modal-content {
			width: 700px;
			height: 360px;
		}
	}
}


/* Extra Small Devices, Phones */
@media only screen and (max-width : 720px) {
	.container {
		width: 340px;
		overflow: hidden;
	}
	br.sm {display: none;}
	br.xs {display: block;}

	/*Disable Animation on Mobile Devices*/
	.animated {
		/*CSS transitions*/
		-o-transition-property: none !important;
		-moz-transition-property: none !important;
		-ms-transition-property: none !important;
		-webkit-transition-property: none !important;
		transition-property: none !important;
		/*CSS transforms*/
		-o-transform: none !important;
		-moz-transform: none !important;
		-ms-transform: none !important;
		-webkit-transform: none !important;
		transform: none !important;
		/*CSS animations*/
		-webkit-animation: none !important;
		-moz-animation: none !important;
		-o-animation: none !important;
		-ms-animation: none !important;
		animation: none !important;
	}
	body {
		background-color: #f3f3f3;
	}
	.main-header {
		padding-top: 15px;
		min-height: 774px;
		// height: 774px;
		position: relative;
		.container {
			overflow: visible;
		}
		.video,
		.canvas {
			min-height: 774px;
			height: 774px;
		}
		.form-block {
			padding-top: 40px;
		}
		.logo {
			margin-bottom: 28px;
			img {
				width: 182px;
				height: 48px;
			}
		}
		.content-block {
			padding-top: 0;
		}
		.date {
			top: 0;
			position: relative;
			font-size: 15px;
			background-color: #3e5ffa;
			line-height: 22px;
			font-weight: 400;
			margin-bottom: 20px;
			span {
				display: inline;
				font-weight: 400;
			}
		}
		.title {
			font-size: 26px;
			line-height: 33px;
			margin-bottom: 30px;
			span {
				font-size: 16px;
				line-height: 24px;
				br {display: none;}
			}
		}
		.desc {
			font-size: 15px;
			line-height: 22px;
			margin-bottom: 45px;
		}
		.bottom-text {
			background: url(../img/xs/bottom-text-xs.png) 50% 0 no-repeat;
			-webkit-background-size: 100%;
			     -o-background-size: 100%;
			        background-size: 100%;
			    padding-left: 85px;
			padding-top: 17px;
			font-size: 14px;
			line-height: 21px;
			padding-right: 30px;
			text-align: left;
			br {display: none;}
		}
		form.standart-form .subm {
			margin-top: 20px;
		}
	}
	.sec1 {
		padding-bottom: 40px;
		.logos {
			height: 29px;
			background: url(../img/xs/logos-xs.png) 50% 0 no-repeat;
			-webkit-background-size: 100%;
			     -o-background-size: 100%;
			        background-size: 100%;
			
		}
	}
	.sec2 {
		padding-top: 46px;
		.sec-title {
			margin-left: 40px;
			padding-top: 33px;
			font-size: 34px;
			line-height: 34px;
			margin-bottom: 65px;
			&:before {
				top: 0;
			}
		}
		.item {
			font-size: 15px;
			padding-left: 80px;
			-webkit-background-size: 75px;
			     -o-background-size: 75px;
			        background-size: 75px;
			margin-bottom: 40px;
		}
	}
	.sec3 {
		padding-bottom: 70px;
		padding-top: 0;
		background-color: #FFF;
		.sec-title {
			margin-left: 30px;
			padding-top: 33px;
			font-size: 34px;
			line-height: 34px;
			margin-bottom: 65px;
			&:before {
				top: 0;
				-webkit-background-size: 127px;
				     -o-background-size: 127px;
				        background-size: 127px;
				width: 127px;
				height: 127px;
			}
		}
		.item {
			padding-left: 0;
			padding-top: 100px;
			-webkit-background-size: 100px;
			     -o-background-size: 100px;
			        background-size: 100px;
			margin-bottom: 50px;
			.item-text {
				font-size: 15px;
			}
			.item-title {
				font-size: 22px;
				line-height: 30px;
				margin-bottom: 20px;
			}
		}
	}
	.sec4 {
		padding-bottom: 75px;
		padding-top: 64px;
		background: #0d0d0d url(../img/xs/sec4-xs.png) 50% 0 no-repeat;
		-webkit-background-size: 100%;
		     -o-background-size: 100%;
		        background-size: 100%;
		.sec-title {
			font-size: 18px;
			line-height: 28px;
			margin-bottom: 45px;
		}
		.day {
			padding-top: 40px;
			padding-left: 25px;
			padding-right: 25px;
			padding-bottom: 40px;
			position: relative;
			.date {
				color: #919191;
				font-size: 13px;
				line-height: 20px;
				margin-bottom: 10px;
				span {
					margin-bottom: 10px;
					display: block;
					color: #000000;
					line-height: 30px;
				}
			}
			.session-number {
				position: absolute;
				top: 40px;
				left: 150px;
				font-size: 15px;
				line-height: 30px;
				span {
					font-size: 15px;
					display: inline-block;
					line-height: 30px;
					float: right;
					vertical-align: top;
					margin-left: 5px;
					transform: translateY(-5px);
				}
			}
			.session-title {
				font-size: 18px;
				line-height: 23px;
				margin-bottom: 20px;
			}
			ul {
				list-style-type: disc;
				li {
					padding-left: 0;
					background: none;
					margin-bottom: 25px;
					font-size: 15px;
					line-height: 24px;
				}
			}
		}
	}
	.sec5 {
		background-color: #FFF;
		padding-top: 50px;
		padding-bottom: 65px;
		.sec-title {
			font-size: 17px;
			line-height: 24px;
			margin-bottom: 40px;
		}
		.block {
			.bi1 {
				height: 203px;
				-webkit-background-size: 100% 203px;
				     -o-background-size: 100% 203px;
				        background-size: 100% 203px;
			}
			.block-number {
				display: block;
				margin: 0;
				margin-bottom: 15px;
				font-size: 42px;
			}
			.block-title {
				margin-bottom: 20px;
			}
			.block-text {
				padding-left: 0;
				padding-right: 0;
			}
		}
		.block2 {
			.video { 
				width: 100%;
				height: 180px;
				}
		}
		.block3 {
			.block-image {
				background-position: 50% 0;
				-webkit-background-size: 100%;
				     -o-background-size: 100%;
				        background-size: 100%;
				height: 148px;
			}
		}
	}
	.sec6 {
		.videobtn {
			width: 300px;
			text-align: left;
			padding-left: 70px;
			height: 64px;
			line-height: 20px;
			padding-top: 10px;
			span {display: block;}
		}
		.infoblock {
			padding-top: 30px;
			padding-left: 45px;
			padding-right: 45px;
			padding-bottom: 30px;
			.block-title {
				font-size: 17px;
				line-height: 22px;
			}
			.oles-name {
				font-size: 26px;
				line-height: 32px;
				margin-bottom: 30px;
			}
			ul {
				li {
					margin-bottom: 25px;
				}
			}
		}
	}
	.modal-overlay {
		.modal-content {
			width: 300px;
			height: 180px;
		}
	}
}


/* Custom, iPhone Retina */
@media only screen and (max-width : 340px) {
		.container {
		padding: 0;
		overflow: hidden;
		width: 320px;}
}


/*********************************************\
		Mobile First Method
\*********************************************/


/* Extra Small Devices, Phones */
@media only screen and (min-width : 320px) {

}


/* Small Devices, Tablets */
@media only screen and (min-width : 720px) {

}


/* Medium Devices, Desktops */
@media only screen and (min-width : 960px) {

}


/* Large Devices, Wide Screens */
@media only screen and (min-width : 1130px) {

}